import React from "react"
import { ParallaxProvider } from 'react-scroll-parallax';

import Navigation from "./Navigation"
import '../../style/main.scss';

const Layout = ({ modal, children }) => {
  return (
    <>
      <Navigation/>
      <main className={modal ? 'modalOpen' : ''}>
        <ParallaxProvider>
          {children}
        </ParallaxProvider>
      </main>
    </>
  )
}

export default Layout
