import React from 'react';

// Brand
const Logo = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 386 120">
		<path d="M267.3,43.2c6.7,0,13.2,2.3,18.4,6.6c6.3,2.4,11.5,6.8,14.9,12.6
      c3.4,5.8,4.6,12.6,3.5,19.2c1.1,6.6-0.1,13.4-3.5,19.2c-3.4,5.8-8.6,10.3-14.9,12.6c-5.2,4.3-11.6,6.6-18.4,6.6s-13.2-2.3-18.4-6.6
      c-6.3-2.4-11.5-6.8-14.9-12.6c-3.4-5.8-4.6-12.6-3.5-19.2c-1.1-6.6,0.1-13.4,3.5-19.2c3.4-5.8,8.6-10.3,14.9-12.6
      C254.2,45.5,260.6,43.2,267.3,43.2z M214.6,46.7v3.5h-3.5v45.1c0,13.7-11.1,24.7-24.7,24.7c-1.3,0-2.6-0.1-3.9-0.3l0,0l2.2-13.8
      c0.5,0.1,1.1,0.1,1.7,0.1c5.9,0,10.8-4.8,10.8-10.8l0,0V50.2h-3.5v-3.5H214.6z M359.6,46.7c13.5,0,24.4,10.9,24.4,24.4
      c0,8.9-4.8,16.7-11.9,21l0,0l10.2,20.9h3.7v3.5h-22.6V113h3.4l-15.3-31.4h8.1c5.8,0,10.5-4.7,10.5-10.5c0-5.8-4.7-10.5-10.5-10.5
      l0,0h-22.1V113h3.5v3.5h-20.9V113h3.5V46.7H359.6z M74.1,43.2l11,69.8h3.5v3.5H67.6V113H71l-5.3-33.5L44.4,110L23,79.5L17.7,113h3.4
      v3.5H0V113h3.5l11-69.8l29.7,42.5L74.1,43.2z M135.8,43.2l34,69.8h3.7v3.5H151V113h3.3l-18.5-38l-18.5,38h3.3v3.5H98.1V113h3.7
      L135.8,43.2z M267.3,110.5c-2.3,0.8-4.8,1.2-7.3,1.3c2.3,0.8,4.7,1.3,7.3,1.3c2.5,0,5-0.5,7.3-1.3
      C272.1,111.7,269.7,111.3,267.3,110.5z M288.1,98.1c-0.1,0.1-0.2,0.1-0.3,0.2c-2.9,1.7-5.9,2.7-9.1,3.1c-2,2.6-4.4,4.7-7.2,6.3
      c-0.1,0.1-0.2,0.1-0.3,0.2c1.4,0.3,2.8,0.4,4.3,0.4c2.3,0,4.5-0.4,6.7-1l0,0c0,0,0,0,0,0C284.9,104.8,286.9,101.7,288.1,98.1z
      M246.6,98.1c1.2,3.5,3.3,6.7,6,9.1l0,0l0,0c2.2,0.7,4.4,1,6.7,1c1.4,0,2.8-0.1,4.2-0.4c-0.1-0.1-0.2-0.1-0.3-0.2
      c-2.8-1.6-5.3-3.8-7.2-6.3c-3.2-0.4-6.2-1.5-9.1-3.1C246.7,98.3,246.7,98.2,246.6,98.1z M267.4,99.7c-2.6,0.9-5.3,1.4-8.1,1.4
      c1.5,1.6,3.2,3.1,5.2,4.2c0.9,0.5,1.9,1,2.8,1.4c1-0.4,1.9-0.8,2.8-1.4c2-1.2,3.7-2.6,5.2-4.2C272.6,101.1,270,100.6,267.4,99.7z
      M297.1,90.4c-1.3,2.1-2.9,4-4.8,5.6c-0.5,2.4-1.3,4.8-2.5,7c1.8-1.6,3.4-3.5,4.7-5.7C295.8,95.1,296.6,92.8,297.1,90.4z
      M237.6,90.4c0.4,2.4,1.3,4.7,2.5,6.9c1.3,2.2,2.9,4.1,4.7,5.7c-1.2-2.2-2-4.5-2.5-7C240.5,94.4,238.9,92.5,237.6,90.4z M254.3,92
      c0.7,2.4,1.7,4.7,3.2,6.8c0.6,0,1.2,0.1,1.8,0.1c1.9,0,3.8-0.3,5.7-0.8c-1.9-0.9-3.6-2.1-5.1-3.4C257.8,94,256,93.1,254.3,92z
      M280.4,92c-1.7,1.1-3.6,2-5.5,2.7c-1.5,1.4-3.2,2.5-5,3.4c1.8,0.5,3.7,0.8,5.7,0.8c0.6,0,1.1,0,1.7-0.1
      C278.7,96.7,279.8,94.4,280.4,92z M288.3,84.3c-1.4,2.4-3.1,4.5-5.2,6.3c-0.5,2.7-1.5,5.4-2.9,7.8c2.2-0.5,4.3-1.3,6.3-2.5
      c0.9-0.5,1.8-1.1,2.6-1.8c0.1-1,0.2-2.1,0.2-3.1C289.3,88.7,288.9,86.4,288.3,84.3z M246.4,84.4c-0.7,2.1-1,4.3-1,6.7
      c0,1.1,0.1,2.1,0.2,3.1l0,0l0.5,0.4c0.7,0.5,1.4,1,2.1,1.4c2,1.2,4.1,2,6.3,2.4c-1.4-2.4-2.3-5-2.8-7.7
      C249.5,88.9,247.8,86.8,246.4,84.4z M267.3,65.9c-2.4,1-4.7,2.4-6.7,4.2c-2.5,0.8-4.8,2.1-6.9,3.7c-0.4,2.6-0.3,5.2,0.3,7.8
      c-0.6,2.6-0.7,5.3-0.3,7.9c2,1.6,4.4,2.8,6.9,3.6c2,1.8,4.3,3.2,6.7,4.2c2.4-1,4.6-2.4,6.6-4.2c2.5-0.8,4.9-2.1,6.9-3.7c0,0,0,0,0,0
      c0,0,0,0,0,0c0.4-2.6,0.3-5.2-0.3-7.8c0.6-2.6,0.7-5.3,0.3-7.9c-2.1-1.6-4.5-2.9-7.1-3.7C271.9,68.2,269.7,66.8,267.3,65.9z
      M292,71.8c0,0.1,0,0.2,0,0.4c0,3.3-0.6,6.4-1.8,9.4c1.2,3,1.9,6.2,1.9,9.5c0,0.1,0,0.2,0,0.3c2.5-2.8,4.1-6.2,4.9-9.8c0,0,0,0,0,0
      l0,0C296.2,78,294.5,74.6,292,71.8z M242.6,71.9c-2.4,2.8-4.1,6.2-4.9,9.7c0,0,0,0,0,0c0,0,0,0,0,0c0.8,3.6,2.5,6.9,4.9,9.8
      c0-0.1,0-0.2,0-0.3c0-3.3,0.6-6.4,1.8-9.4c-1.2-3-1.9-6.2-1.9-9.5C242.6,72.1,242.6,72,242.6,71.9z M251.8,75.5
      c-1.8,1.8-3.2,3.8-4.3,6.1c1.1,2.3,2.6,4.4,4.4,6.2c-0.1-2.1,0-4.2,0.4-6.2C251.8,79.6,251.7,77.5,251.8,75.5z M282.9,75.4
      c0.1,2.1,0,4.2-0.4,6.2c0.4,2,0.6,4,0.5,6.1c1.8-1.8,3.2-3.8,4.3-6.1C286.1,79.2,284.6,77.2,282.9,75.4z M254.5,64.8
      c-2.2,0.5-4.3,1.3-6.3,2.5c-0.9,0.5-1.8,1.1-2.6,1.7c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,1-0.2,2.1-0.2,3.1c0,2.3,0.4,4.6,1.1,6.7
      c1.4-2.4,3.1-4.5,5.2-6.3C252.1,69.9,253.1,67.2,254.5,64.8z M280.2,64.8c1.4,2.4,2.3,5,2.8,7.7c2.1,1.8,3.9,3.9,5.3,6.3
      c0.7-2.1,1-4.3,1-6.7c0-1.1-0.1-2.1-0.2-3.1c-0.8-0.6-1.7-1.2-2.6-1.8C284.5,66.1,282.3,65.3,280.2,64.8z M289.8,60.2
      c1.2,2.2,2,4.5,2.5,7c1.9,1.6,3.5,3.6,4.8,5.6c-0.4-2.4-1.3-4.7-2.5-6.9C293.3,63.7,291.6,61.8,289.8,60.2z M244.9,60.2
      c-1.8,1.6-3.4,3.5-4.7,5.7c-1.3,2.2-2.1,4.6-2.5,6.9c1.3-2.1,2.9-4,4.8-5.6C242.9,64.7,243.7,62.4,244.9,60.2z M259.1,64.3
      c-0.6,0-1.1,0-1.7,0.1c-1.5,2.1-2.5,4.4-3.2,6.9c1.7-1.1,3.5-2,5.5-2.7c1.6-1.4,3.3-2.6,5.1-3.5C263,64.6,261.1,64.3,259.1,64.3z
      M275.4,64.3c-1.9,0-3.9,0.3-5.7,0.8c1.8,0.9,3.5,2.1,5,3.4c2,0.7,3.9,1.6,5.7,2.8c-0.6-2.4-1.7-4.8-3.2-6.9
      C276.7,64.3,276.1,64.3,275.4,64.3z M275.5,54.9c-1.4,0-2.8,0.1-4.2,0.4c0.1,0.1,0.2,0.1,0.3,0.2c2.8,1.6,5.2,3.7,7.2,6.3
      c3.2,0.4,6.3,1.5,9.1,3.1c0.1,0.1,0.2,0.1,0.3,0.2c-1.2-3.5-3.3-6.7-6-9.2c0,0,0,0,0,0c0,0,0,0,0,0C280,55.2,277.8,54.9,275.5,54.9z
      M259.2,54.9c-2.3,0-4.5,0.4-6.7,1l0,0c0,0,0,0,0,0c-2.7,2.5-4.8,5.6-6,9.1c0.1,0,0.2-0.1,0.2-0.2c2.8-1.6,5.9-2.7,9.1-3.1
      c2-2.6,4.4-4.7,7.2-6.3c0.1-0.1,0.2-0.1,0.3-0.2C262,55,260.6,54.9,259.2,54.9z M267.3,56.5l-0.6,0.2c-0.8,0.3-1.5,0.7-2.3,1.1
      c-2,1.2-3.7,2.6-5.2,4.2c2.7,0,5.4,0.5,8,1.4c2.6-0.9,5.3-1.4,8.1-1.4c-1.5-1.6-3.2-3.1-5.2-4.2C269.3,57.3,268.3,56.8,267.3,56.5
      L267.3,56.5z M267.3,50.2c-2.5,0-5,0.5-7.3,1.3c2.5,0.1,4.9,0.5,7.3,1.3c2.3-0.8,4.8-1.2,7.3-1.3C272.3,50.6,269.9,50.2,267.3,50.2z
      M141.5,4.8l4.2,8.6l4.2-8.6h3.1l-7.3,15l-7.3-15H141.5z M239.6,3.8c2.1,0,4.1,0.8,5.5,2.2l0,0l-2.8,1.4c-0.8-0.5-1.7-0.8-2.7-0.8
      c-2.9,0-5.2,2.3-5.2,5.2c0,2.9,2.3,5.2,5.2,5.2c2.4,0,4.4-1.6,5-3.8l0,0h-5v-2.8h7.8c0.1,0.5,0.1,0.9,0.1,1.4c0,4.4-3.6,8-8,8
      c-4.4,0-8-3.6-8-8C231.6,7.4,235.2,3.8,239.6,3.8z M190.3,4.8c2.7,0,4.9,2.2,4.9,4.9c0,1.8-1,3.3-2.4,4.2l0,0l2.4,4.9h-3.1l-3.4-7
      h1.6c1.2,0,2.1-0.9,2.1-2.1c0-1.2-0.9-2.1-2.1-2.1l0,0h-4.4v11.2h-2.8v-14H190.3z M213.7,3.8l7.3,15h-3.1l-4.2-8.6l-4.2,8.6h-3.1
      L213.7,3.8z M169.7,4.8v14h-2.8v-14H169.7z M218.6,0l-1.4,2.8h-3.1l1.4-2.8H218.6z"/>
  </svg>
);
const Embleme = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72">
		<path d="M61.6,50.7c-1.2,2.1-2.7,3.8-4.5,5.3c1.1-2,1.9-4.2,2.4-6.5c1.8-1.5,3.3-3.3,4.5-5.3
      C63.6,46.5,62.8,48.7,61.6,50.7 M29.2,64.3c2.3-0.1,4.6-0.5,6.8-1.2c2.2,0.7,4.5,1.1,6.8,1.2c-2.1,0.7-4.4,1.2-6.8,1.2
      C33.6,65.5,31.3,65,29.2,64.3 M10.4,50.7c-1.2-2.1-2-4.3-2.4-6.5c1.2,2,2.7,3.8,4.5,5.3c0.5,2.3,1.3,4.5,2.4,6.5
      C13.1,54.6,11.6,52.8,10.4,50.7 M10.4,21.3c1.2-2.1,2.7-3.8,4.5-5.3c-1.1,2-1.9,4.2-2.4,6.5C10.7,24,9.2,25.8,8,27.8
      C8.4,25.5,9.2,23.3,10.4,21.3 M42.9,7.7c-2.3,0.1-4.6,0.5-6.8,1.2c-2.2-0.7-4.5-1.1-6.8-1.2C31.3,7,33.6,6.5,36,6.5
      C38.4,6.5,40.7,7,42.9,7.7 M61.6,21.3c1.2,2.1,2,4.3,2.4,6.5c-1.2-2-2.7-3.8-4.5-5.3c-0.5-2.3-1.3-4.5-2.4-6.5
      C58.9,17.4,60.4,19.2,61.6,21.3 M57.5,36c1.2-2.8,1.7-5.8,1.7-8.8c0-0.1,0-0.2,0-0.3c2.3,2.6,3.9,5.8,4.7,9.2c0,0,0,0,0,0s0,0,0,0
      c-0.7,3.3-2.3,6.5-4.6,9.1c0-0.1,0-0.2,0-0.3C59.3,41.8,58.7,38.8,57.5,36 M50.8,44.4c2-1.7,3.6-3.7,4.9-5.9c0.6,2,1,4.1,1,6.3
      c0,1-0.1,2-0.2,2.9c-0.8,0.6-1.6,1.2-2.5,1.7c-1.9,1.1-3.9,1.9-6,2.3C49.4,49.5,50.3,47,50.8,44.4 M50.2,36c0.4-1.9,0.5-3.9,0.4-5.8
      c1.7,1.7,3.1,3.6,4.1,5.8c-1,2.1-2.4,4.1-4,5.7C50.8,39.8,50.6,37.9,50.2,36 M21.8,36c-0.4,1.9-0.5,3.9-0.4,5.8
      c-1.7-1.7-3.1-3.6-4.1-5.8c1-2.1,2.4-4.1,4.1-5.8C21.2,32.2,21.4,34.1,21.8,36 M48.9,43.3C48.9,43.3,48.8,43.3,48.9,43.3
      C48.8,43.3,48.8,43.3,48.9,43.3c-2,1.6-4.2,2.8-6.6,3.5c-1.9,1.7-3.9,3-6.2,3.9c-2.3-0.9-4.5-2.2-6.3-3.9c-2.4-0.7-4.6-1.9-6.5-3.4
      c-0.4-2.4-0.3-4.9,0.3-7.4c-0.6-2.4-0.6-4.9-0.3-7.3c1.9-1.5,4.1-2.7,6.5-3.5c1.9-1.7,4-3,6.3-4c2.3,0.9,4.4,2.2,6.2,3.9
      c2.4,0.8,4.7,2,6.7,3.5c0.4,2.4,0.3,4.9-0.3,7.4C49.1,38.4,49.2,40.9,48.9,43.3 M45.4,19.8c1.4,2,2.4,4.1,3,6.4
      c-1.7-1.1-3.4-2-5.3-2.6c-1.4-1.3-3-2.3-4.8-3.2c1.8-0.5,3.6-0.7,5.4-0.7C44.2,19.8,44.8,19.8,45.4,19.8 M26.6,19.8
      c0.5,0,1.1-0.1,1.6-0.1c1.8,0,3.6,0.3,5.4,0.7c-1.8,0.9-3.4,2-4.8,3.3c-1.8,0.6-3.5,1.5-5.1,2.5C24.2,24,25.3,21.8,26.6,19.8
      M26.6,52.2c-1.4-2-2.4-4.1-3-6.4c1.6,1.1,3.4,1.9,5.2,2.5c1.5,1.3,3.1,2.4,4.8,3.2c-1.7,0.5-3.5,0.7-5.4,0.7
      C27.8,52.2,27.2,52.2,26.6,52.2 M43.7,52.2c-1.8,0-3.6-0.3-5.3-0.7c1.7-0.9,3.3-1.9,4.8-3.2c1.8-0.6,3.6-1.5,5.2-2.5
      c-0.6,2.3-1.6,4.5-3,6.4C44.8,52.2,44.3,52.2,43.7,52.2 M55.7,33.4c-1.3-2.3-3-4.3-5-5.9c-0.5-2.5-1.4-5-2.7-7.2
      c2,0.4,4,1.2,5.9,2.3c0.9,0.5,1.7,1.1,2.5,1.7c0.1,1,0.2,1.9,0.2,2.9C56.7,29.3,56.4,31.4,55.7,33.4 M36,12.4L36,12.4
      c0.9,0.4,1.8,0.8,2.7,1.3c1.9,1.1,3.5,2.4,4.9,4c-2.6,0-5.2,0.4-7.7,1.3c-2.4-0.9-4.9-1.3-7.5-1.3c1.4-1.5,3-2.9,4.9-4
      C34.2,13.2,35.1,12.8,36,12.4 M15.5,24.2C15.5,24.2,15.5,24.2,15.5,24.2c0.8-0.6,1.6-1.2,2.4-1.7c1.9-1.1,3.9-1.9,6-2.3
      c-1.3,2.3-2.2,4.7-2.7,7.3c-2,1.7-3.6,3.7-4.9,5.9c-0.6-2-1-4.1-1-6.3C15.3,26.2,15.3,25.2,15.5,24.2C15.5,24.2,15.5,24.2,15.5,24.2
      M15.5,47.8L15.5,47.8c-0.1-1-0.2-1.9-0.2-2.9c0-2.2,0.3-4.3,1-6.2c1.3,2.3,3,4.3,5,5.9c0.5,2.5,1.4,5,2.7,7.2c-2-0.4-4-1.2-5.9-2.3
      C17.1,48.9,16.3,48.4,15.5,47.8 M36,59.6c-0.9-0.4-1.8-0.8-2.7-1.3c-1.9-1.1-3.5-2.4-4.9-3.9c2.6,0,5.2-0.5,7.7-1.3
      c2.4,0.9,4.9,1.3,7.5,1.3c-1.4,1.5-3,2.9-4.9,4C37.8,58.8,36.9,59.2,36,59.6 M50,11.9C50,11.9,50,11.9,50,11.9
      C50,11.9,50,11.9,50,11.9c2.6,2.3,4.5,5.3,5.6,8.6c-0.1-0.1-0.2-0.1-0.3-0.2c-2.7-1.5-5.6-2.5-8.6-2.9C44.9,15,42.6,13,40,11.5
      c-0.1-0.1-0.2-0.1-0.3-0.1c1.3-0.3,2.7-0.4,4-0.4C45.8,11,47.9,11.3,50,11.9 M22,11.9C22,11.9,22,11.9,22,11.9L22,11.9
      c2-0.6,4.2-1,6.3-1c1.3,0,2.7,0.1,4,0.4c-0.1,0.1-0.2,0.1-0.3,0.1c-2.7,1.5-5,3.5-6.8,5.9c-3,0.4-5.9,1.4-8.6,2.9
      c-0.1,0-0.2,0.1-0.2,0.1C17.5,17.2,19.5,14.2,22,11.9 M8.1,36c0.7-3.3,2.3-6.5,4.6-9.1c0,0.1,0,0.2,0,0.3c0,3,0.6,6.1,1.8,8.9
      c-1.2,2.8-1.7,5.8-1.7,8.8c0,0.1,0,0.2,0,0.3C10.4,42.5,8.8,39.4,8.1,36C8.1,36,8.1,36,8.1,36C8.1,36,8.1,36,8.1,36 M22,60.1
      L22,60.1C22,60.1,22,60.1,22,60.1c-2.5-2.3-4.5-5.3-5.6-8.6c0.1,0.1,0.2,0.1,0.3,0.2c2.7,1.5,5.5,2.5,8.6,2.9
      c1.9,2.4,4.1,4.4,6.8,5.9c0.1,0.1,0.2,0.1,0.3,0.2c-1.3,0.3-2.7,0.4-4,0.4C26.2,61,24.1,60.7,22,60.1 M50,60.1
      C50,60.1,50,60.1,50,60.1L50,60.1c-2,0.6-4.2,1-6.3,1c-1.3,0-2.7-0.1-4-0.4c0.1-0.1,0.2-0.1,0.3-0.1c2.7-1.5,4.9-3.5,6.8-5.9
      c3-0.4,5.9-1.4,8.6-2.9c0.1-0.1,0.2-0.1,0.3-0.2C54.5,54.8,52.5,57.8,50,60.1 M67.3,18c-3.2-5.5-8.1-9.6-14-11.8
      C48.4,2.2,42.3,0,36,0S23.6,2.2,18.7,6.2C12.8,8.4,7.8,12.5,4.7,18c-3.2,5.5-4.3,11.8-3.3,18c-1,6.2,0.1,12.5,3.3,18
      c3.2,5.5,8.1,9.6,14,11.8c4.9,4,11,6.2,17.3,6.2s12.4-2.2,17.3-6.2c5.9-2.2,10.8-6.4,14-11.8c3.2-5.5,4.3-11.8,3.3-18
      C71.7,29.8,70.5,23.5,67.3,18"/>
  </svg>
);

// Social
const Facebook = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
		<path d="M28.3,0H1.7C0.7,0,0,0.7,0,1.7v26.7C0,29.3,0.7,30,1.7,30H16V18.4h-3.9v-4.5H16v-3.3c0-3.9,2.4-6,5.8-6
	    c1.7,0,3.1,0.1,3.5,0.2v4l-2.4,0c-1.9,0-2.2,0.9-2.2,2.2v2.9h4.5l-0.6,4.5h-3.9V30h7.6c0.9,0,1.7-0.7,1.7-1.7V1.7
	    C30,0.7,29.3,0,28.3,0z"/>
  </svg>
);
const Instagram = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
  	<path d="M29.9,8.8c-0.1-1.6-0.3-2.7-0.7-3.6c-0.4-1-1-1.9-1.7-2.7c-0.8-0.8-1.7-1.4-2.7-1.7c-1-0.4-2-0.6-3.6-0.7
			C19.6,0,19.1,0,15,0s-4.6,0-6.2,0.1C7.2,0.2,6.1,0.4,5.2,0.8c-1,0.4-1.9,1-2.7,1.7C1.8,3.3,1.2,4.2,0.8,5.2c-0.4,1-0.6,2-0.7,3.6
			C0,10.4,0,10.9,0,15s0,4.6,0.1,6.2c0.1,1.6,0.3,2.7,0.7,3.6c0.4,1,1,1.9,1.7,2.7c0.8,0.8,1.7,1.4,2.7,1.7c1,0.4,2,0.6,3.6,0.7
			C10.4,30,10.9,30,15,30s4.6,0,6.2-0.1c1.6-0.1,2.7-0.3,3.6-0.7c2-0.8,3.6-2.4,4.4-4.4c0.4-1,0.6-2,0.7-3.6C30,19.6,30,19.1,30,15
			S30,10.4,29.9,8.8z M27.2,21.1c-0.1,1.5-0.3,2.3-0.5,2.8c-0.5,1.3-1.5,2.3-2.8,2.8c-0.5,0.2-1.3,0.5-2.8,0.5
			c-1.6,0.1-2.1,0.1-6.1,0.1s-4.5,0-6.1-0.1c-1.5-0.1-2.3-0.3-2.8-0.5c-0.7-0.2-1.2-0.6-1.7-1.1c-0.5-0.5-0.9-1.1-1.1-1.7
			c-0.2-0.5-0.5-1.3-0.5-2.8C2.7,19.5,2.7,19,2.7,15c0-4,0-4.5,0.1-6.1c0.1-1.5,0.3-2.3,0.5-2.8c0.2-0.7,0.6-1.2,1.1-1.7
			C4.9,4,5.5,3.6,6.2,3.3C6.7,3.1,7.5,2.9,9,2.8C10.5,2.7,11,2.7,15,2.7c4,0,4.5,0,6.1,0.1c1.5,0.1,2.3,0.3,2.8,0.5
			c0.7,0.2,1.2,0.6,1.7,1.1c0.5,0.5,0.9,1.1,1.1,1.7c0.2,0.5,0.5,1.3,0.5,2.8c0.1,1.6,0.1,2.1,0.1,6.1C27.3,19,27.3,19.5,27.2,21.1z
			 M15,7.3c-4.3,0-7.7,3.5-7.7,7.7c0,4.3,3.5,7.7,7.7,7.7c4.3,0,7.7-3.5,7.7-7.7C22.7,10.8,19.3,7.3,15,7.3z M15,20
			c-2.8,0-5-2.2-5-5c0-2.8,2.2-5,5-5c2.8,0,5,2.2,5,5C20,17.8,17.8,20,15,20z M23,5.2c-1,0-1.8,0.8-1.8,1.8c0,1,0.8,1.8,1.8,1.8
			c1,0,1.8-0.8,1.8-1.8C24.8,6,24,5.2,23,5.2z"/>
  </svg>
);


// Decor
const Pattern = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 440 500">
  	<path d="M436.7,134.4l-1.2-8.6l-6.7-5.4c-30.5-24.2-64.5-42.8-101.2-55.3c-29.3-26.4-62.8-47.2-99.6-62L220,0l-8,3.2
			c-36.8,14.7-70.3,35.6-99.6,62C75.8,77.6,41.7,96.2,11.2,120.5l-6.7,5.4l-1.2,8.6c-5.4,38.7-4.1,77.7,3.9,116
			c-7.9,38.6-9,78-3.2,117.2l1.2,8.5l6.8,5.3c30.7,24.1,65.1,42.4,102.1,54.6c29.4,26.2,62.9,46.8,99.6,61.4l6.2,2.5l6.2-2.5
			c36.7-14.6,70.2-35.3,99.6-61.4c37-12.1,71.4-30.5,102.1-54.6l6.8-5.3l1.2-8.5c5.8-39.2,4.7-78.6-3.2-117.2
			C440.8,212.1,442.2,173.1,436.7,134.4z M414.9,364.3c-29.4,23.1-63.2,41-99.9,52.4c-28.2,25.8-60.4,45.9-95,59.8
			c-34.6-13.9-66.8-34-95-59.8c-36.7-11.4-70.4-29.4-99.9-52.4c-5.5-37.5-4.4-76.2,3.9-114.1c-8.5-37.6-9.8-75.8-4.6-112.9
			c29.2-23.2,62.7-41.4,99-53.1c28.5-26.4,61.3-46.9,96.6-61c35.2,14.1,68,34.6,96.6,61c36.3,11.8,69.7,29.9,99,53.1
			c5.2,37.1,3.8,75.3-4.6,112.9C419.2,288.1,420.4,326.7,414.9,364.3z"/>
	</svg>
);


// Icons
const IconCare = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
  	<path d="M13.8,24.2c0.9,0.9,2,1.5,3.2,1.7V28h2v-2.1c2.5-0.4,4.5-2.4,4.9-4.9H27v-2h-3.1c-0.5-3.3-3.6-5.5-6.9-4.9
			c-0.9,0.2-1.7,0.5-2.5,1l-2.2-2.2l-1.4,1.4l2.2,2.2c-0.5,0.7-0.9,1.6-1,2.5H10v2h2.1C12.3,22.2,12.9,23.4,13.8,24.2z M15.1,17.2
			c1.5-1.6,4.1-1.6,5.7-0.1c0,0,0,0,0.1,0.1c1.6,1.6,1.6,4.1,0,5.7c0,0,0,0,0,0c-1.6,1.5-4.1,1.5-5.7,0
			C13.6,21.3,13.6,18.8,15.1,17.2z M10.2,26.4l1.4,1.4l1.4-1.4l-1.4-1.4L10.2,26.4z M8,19H6v2h2V19z M25.8,13.6l-1.4-1.4l-1.4,1.4
			l1.4,1.4L25.8,13.6z M19,10h-2v2h2V10z M2,50h2v-2H2V50z M51,48v2h7v-2H51z M46,27h-6v-2.5c2.4-0.7,4-3,4-5.5v-1c1.7,0,3-1.3,3-3
			s-1.3-3-3-3v-2h3V8h-3c0-3.6-2.8-6-7-6s-7,2.4-7,6h-1c-0.6,0-1-0.4-1-1V6h-2v1c0,1.7,1.3,3,3,3h1v2c-1.7,0-3,1.3-3,3s1.3,3,3,3v1
			c0,2.5,1.6,4.8,4,5.5V27h-6c-2.8,0-5,1.8-5,4.1V37H10v11H6v2h4v8h37v-8h2v-2h-2v-2.1c2.3-0.5,4-2.5,4-4.9v-9.9
			C51,28.8,48.7,27,46,27z M44,14c0.6,0,1,0.4,1,1c0,0.6-0.4,1-1,1V14z M30,16c-0.6,0-1-0.4-1-1c0-0.6,0.4-1,1-1V16z M37,4
			c2.4,0,5,1.1,5,4H32C32,5.1,34.5,4,37,4z M32,10h6v1c0,0.6-0.4,1-1,1h-5V10z M32,19v-5h5c1.7,0,3-1.3,3-3v-1h2v9c0,2.9-2.6,4-5,4
			S32,21.9,32,19z M39,29c0.1,1-0.7,1.9-1.8,2c-0.1,0-0.2,0-0.2,0h-2v-2H39z M36,27v-2.1c0.3,0,0.7,0.1,1,0.1s0.7,0,1-0.1V27H36z
			 M31,35.4V29h2v7h2v-3h2c2.1,0.1,3.9-1.6,4-3.8c0-0.1,0-0.2,0-0.2h2v6.4l1,1V37h-8.2L30,38.9l0-2.5L31,35.4z M25,31.1
			c0-1.1,1.4-2.1,3-2.1h1v5.6l-1,1V37h-3V31.1z M18,39h5v2c0,0.7,0.1,1.4,0.4,2H18V39z M12,39h4v4h-4V39z M27,56H12V45h13
			c0.6,0.4,1.3,0.6,2,0.8V56z M25,41v-2h3v2h2.2l6-2h8.1c0.2,0,0.5,0,0.7,0v1c0,0.6-0.4,1-1,1h-6.2l-9,3C25,43.9,25,41.7,25,41z
			 M31,46v2.2l-2,0.5V46h0.2l8.3-2.8l6.7,2.4c0.3,0.1,0.5,0.2,0.8,0.2v2.8l-2-0.5V46H31z M45,56h-2v-2h-2v2h-8v-2h-2v2h-2v-5.2l4-1
			V48h8v1.8l4,1V56z M49,41c0,1-0.5,1.9-1.3,2.5c-0.8,0.6-1.9,0.7-2.8,0.3L42.7,43H44c1.7,0,3-1.3,3-3v-1h2V41z M49,37h-3v-1.4l-1-1
			V29h1c1.6,0,3,1,3,2.1V37z"/>
  </svg>
);
const IconGreenhouse = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
  	<path class="st0" d="M42.7,22.5v-2h3v-2h-25c-9.4,0-17,7.6-17,17v20h-2v2h56v-23h0C57.4,28.9,54.3,22.8,42.7,22.5z M41.7,24.5
			c9,0,13.6,3.3,14,10h-8C47.5,30.8,46,26.8,41.7,24.5L41.7,24.5z M21.7,20.5h19v2h-19V20.5z M21.7,24.5c3,0,5.9,0.6,8.6,1.8l-8.6,7
			V24.5z M32.1,27.4c2.2,1.7,3.5,4.3,3.6,7H23.5L32.1,27.4z M19.7,20.5v3v9.7l-9.3-8.6C12.9,22.2,16.2,20.7,19.7,20.5z M9,26.1
			l9.1,8.4H5.7C5.9,31.4,7.1,28.5,9,26.1z M15.7,41.5v14h-10v-8v-11h14v4h-3C16.1,40.5,15.7,40.9,15.7,41.5z M23.7,47.5h-2v2h2v6h-1
			h-5v-13h6V47.5z M35.7,55.5h-10v-14c0-0.6-0.4-1-1-1h-3v-4h14V55.5z M31.1,24.5h0.6c9,0,13.6,3.3,14,10h-8
			C37.6,30.1,35,26.3,31.1,24.5z M55.7,55.5h-18v-19h18V55.5z M6.9,15.5h9c2.2,0,4-1.8,4-4c0-2.2-1.8-4-4-4h-1.1
			C14.2,5.3,12,4,9.9,4.6C8.5,5,7.4,6.1,7,7.5H6.9c-2.2,0-4,1.8-4,4C2.9,13.7,4.7,15.5,6.9,15.5z M6.9,9.5h1c0.6,0,1-0.4,1-1
			c0-1.1,0.9-2,2-2c1.1,0,2,0.9,2,2v3h2v-2h1c1.1,0,2,0.9,2,2c0,1.1-0.9,2-2,2h-9c-1.1,0-2-0.9-2-2C4.9,10.4,5.8,9.5,6.9,9.5z
			 M6.6,20h5v-2h-5V20z M4.6,18h-2v2h2V18z M21.7,2.5h-5v2h5V2.5z M25.7,2.5h-2v2h2V2.5z M40.7,12.5c0,2.2,1.8,4,4,4h9
			c2.2,0,4-1.8,4-4c0-2.2-1.8-4-4-4h-1.1C52,6.3,49.8,5,47.7,5.6c-1.4,0.4-2.5,1.5-2.9,2.9H32.7v2h8.6
			C40.9,11.1,40.7,11.8,40.7,12.5z M44.7,10.5h1c0.6,0,1-0.4,1-1c0-1.1,0.9-2,2-2c1.1,0,2,0.9,2,2v3h2v-2h1c1.1,0,2,0.9,2,2
			c0,1.1-0.9,2-2,2h-9c-1.1,0-2-0.9-2-2C42.7,11.4,43.6,10.5,44.7,10.5z M51.7,20.5h4v-2h-4V20.5z M30.7,8.5h-4v2h4V8.5z M49.7,20.5
			v-2h-2v2H49.7z"/>
  </svg>
);
const IconRenewable = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
  	<path d="M48,56V34h4c0.6,0,1-0.4,1-1v-4c0-0.2,0-0.3-0.1-0.5l-8-14C44.7,14.2,44.4,14,44,14h-4c0-0.5,0-0.9-0.1-1.4
			l2.4-0.7L41.7,10l-2.3,0.7c-0.5-1.3-1.2-2.6-2.2-3.6l1.5-1.4l-1.4-1.5l-1.6,1.5c-1.4-1-3-1.6-4.7-1.7V2h-2v2.1
			c-1.7,0.2-3.4,0.8-4.8,1.8l-1.4-1.6l-1.5,1.3l1.4,1.5c-0.9,1-1.6,2.2-2.1,3.5L18.3,10L17.7,12l2.4,0.7C20,13.1,20,13.5,20,14h-4
			c-0.4,0-0.7,0.2-0.9,0.5l-8,14C7,28.7,7,28.8,7,29v4c0,0.6,0.4,1,1,1h4v22L2,56v2l56,0v-2L48,56z M50.3,28h-8.5l-0.9-3h7.7
			L50.3,28z M45.7,20l1.7,3h-7.1l-0.9-3H45.7z M39.7,28H31v-3h7.8L39.7,28z M43.4,16l1.1,2h-5.7l-0.6-2H43.4z M31,23v-3h6.4l0.9,3
			H31z M31,18v-2h5.2l0.6,2H31z M30,6c4.4,0,8,3.6,8,8H22C22,9.6,25.6,6,30,6z M29,16v2h-5.8l0.6-2H29z M22.6,20H29v3h-7.2L22.6,20z
			 M29,25v3h-8.7l0.9-3H29z M16.6,16h5.1l-0.6,2h-5.7L16.6,16z M14.3,20h6.2l-0.9,3h-7.1L14.3,20z M11.4,25h7.7l-0.9,3H9.7L11.4,25z
			 M9,32v-2h42v2H9z M46,56H14V34h32V56z M16.9,9.7l-4.4-1.2L12,10.3l4.4,1.2L16.9,9.7z M21.8,3.3L20.6,2l-1.5,1.3l1.2,1.3L21.8,3.3
			z M48,10.3l-0.5-1.9l-4.4,1.2l0.5,1.9L48,10.3z M40.9,3.3L39.4,2l-1.2,1.3l1.5,1.3L40.9,3.3z M25,48.5c0.2,0.1,0.5,0.2,0.8,0.2h2
			v3.6c0,0.8,0.5,1.5,1.3,1.7c0.1,0,0.3,0.1,0.4,0.1c0.6,0,1.2-0.4,1.5-0.9l4.6-8.4c0.5-0.9,0.1-1.9-0.7-2.4
			c-0.3-0.1-0.5-0.2-0.8-0.2h-2v-4.3c0-0.5-0.2-0.9-0.5-1.2c-0.3-0.3-0.8-0.5-1.2-0.5c-0.7,0-1.3,0.4-1.6,1l-4.6,9.1
			C23.8,47,24.2,48,25,48.5z M30.1,38.8v5.3h3.6l-3.9,7.2v-4.6h-3.7L30.1,38.8z"/>
  </svg>
);
const IconSeedling = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
  	<path d="M24,7.5h-2v6h2V7.5z M24,3.5h-2v2h2V3.5z M19.3,16.2l1.4-1.4l-4.6-4.6l-1.4,1.4L19.3,16.2z M14.7,8.8
			l-1.4-1.4l-1.4,1.4l1.4,1.4L14.7,8.8z M18,17.5h-8v2h8V17.5z M8,17.5H6v2h2V17.5z M43.9,27l-3.2-3.2l-1.4,1.4l3.2,3.2L43.9,27z
			 M45.3,31.2l1.4-1.4l-1.4-1.4l-1.4,1.4L45.3,31.2z M50,19.9h-9.3v2H50V19.9z M54,19.9h-2v2h2V19.9z M42.7,16l-1.4-1.4l-2,2
			l1.4,1.4L42.7,16z M45.5,13.2l-1.4-1.4l-1.4,1.4l1.4,1.4L45.5,13.2z M31.1,27.8c0.2-1.5,0.1-2.9-0.1-4.4c1.4-0.1,2.8-0.8,3.7-1.8
			c0.9-1.2,1.3-2.8,1-4.3c-0.1-0.5-0.5-0.8-1-0.8c-2.3,0-3.9,0.6-5,1.9c-0.3,0.3-0.5,0.7-0.6,1.1c-1.3-1.4-3.2-2.1-5.1-2
			c-0.5,0-0.9,0.4-1,0.9c-0.3,2.1,0.3,4.3,1.6,6c1.2,1.3,2.9,2.1,4.6,2.1c0,0.5-0.1,0.9-0.1,1.2c-1.9,0.8-3.1,4-3.1,5.8
			c0,2.1,1.6,6,4,6c2.4,0,4-3.9,4-6C34,31.8,32.9,28.7,31.1,27.8z M26.1,23.1c-0.8-1-1.2-2.2-1.2-3.5c1.1,0.1,2.2,0.6,2.9,1.5
			c0.7,1,1.2,2.2,1.3,3.4C28,24.4,26.9,23.9,26.1,23.1z M31.3,19.7c0.6-0.7,1.5-1.1,2.5-1.1c0,0.6-0.2,1.3-0.6,1.8
			c-0.6,0.7-1.5,1.1-2.5,1.1C30.7,20.8,30.9,20.2,31.3,19.7z M30,37.5c-0.7,0-2-2.5-2-4c0-1.5,1.3-4,2-4s2,2.5,2,4
			C32,35,30.7,37.5,30,37.5z M26.4,46.2C26.4,46.2,26.3,46.2,26.4,46.2c-1.6-1.1-3.2-2-4.9-2.8c-1.7-0.9-3.6-2-4.9-2.8
			c2.3-0.5,5.9-1.5,7.2-3.2c0.4-0.5,0.6-1.2,0.5-1.9c-0.1-1.7-1.6-3.1-3.4-3c-0.1,0-0.2,0-0.3,0.1c-1.8,0.7-5.1,1.7-6.4,1.4
			c-0.7-0.2-1.3-0.6-1.9-1.1c-0.9-0.9-2.1-1.4-3.4-1.5c-1.4,0.1-2.7,0.4-4.1,0.8L2,29.8v2.6l2,1.7c0.3,0.2,0.6,0.3,1,0.2
			c1.3-0.5,2.7-0.8,4.1-0.9c0.6-0.1,1.2,0.4,2,1c0.8,0.7,1.7,1.2,2.8,1.5c2,0.4,6.3-1.1,7.3-1.5c0.3,0,1,0.2,1.2,1.3
			c0,0.1,0,0.3-0.1,0.4c-1,1.3-5.4,2.4-7.9,2.8c-0.2,0-0.4,0.1-0.6,0.3l0,0c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0.1c-3,3.2,1.8,8.7,5,12.3
			c0.7,0.7,1.3,1.5,1.9,2.3c0,0.1,0,0.3-0.1,0.4c-0.6,0.1-1.2,0-1.7-0.4c-2-1.3-4.6-4.2-7.1-7c-3-3.4-5.8-6.7-8.2-7.7
			c0,0-0.1,0-0.1,0L2,38.9V41l0.6,0.2c2,0.9,4.7,4,7.4,7.1c2.6,3,5.3,6,7.5,7.4c0.7,0.5,1.6,0.8,2.5,0.8c0.5,0,0.9-0.1,1.3-0.3
			c0.2-0.1,0.4-0.3,0.6-0.5c0.4,0.1,0.7,0.1,1.1,0.1c1.1,0,2.2-0.5,2.8-1.5c0.5-0.8,0.5-1.8,0.2-2.7c0.4-0.2,0.8-0.5,1-0.9
			C28.1,49.3,27.8,47.3,26.4,46.2z M24.1,53.3L24.1,53.3c-0.3,0.5-0.9,0.6-1.7,0.4c-0.1-0.7-0.7-1.4-2.3-3.3c-2-2.3-5.4-6.2-5.4-8.5
			c2,2.4,6.3,7.5,8.8,9.4C24.1,51.7,24.4,52.5,24.1,53.3z M25.5,49.4c0,0,0,0.1-0.1,0.1h0c-0.1,0.3-0.5,0.4-0.8,0.3c0,0-0.1,0-0.1,0
			c-1.8-1.6-3.5-3.3-5.1-5.1l1,0.6c1.9,1,3.8,2.1,4.5,2.6C25.7,48.1,25.9,48.8,25.5,49.4z M38.9,34.5c1,0.4,5.2,1.8,7.3,1.5
			c1-0.2,2-0.8,2.8-1.5c0.8-0.7,1.4-1.1,2-1c1.4,0.2,2.8,0.5,4.1,0.9c0.3,0.1,0.7,0,1-0.2l2-1.7v-2.6l-2.9,2.5
			c-1.3-0.4-2.7-0.7-4.1-0.8c-1.3,0-2.5,0.5-3.4,1.5c-0.5,0.5-1.2,0.9-1.9,1.1c-1.2,0.2-4.6-0.8-6.4-1.4c-0.1,0-0.2-0.1-0.3-0.1
			c-1.8-0.1-3.3,1.3-3.4,3c-0.1,0.7,0.1,1.3,0.5,1.9c1.3,1.7,4.9,2.7,7.2,3.2c-1.3,0.8-3.2,1.9-4.9,2.8c-1.6,0.8-3.2,1.8-4.8,2.8
			c-1.5,1-1.8,3-0.8,4.5c0,0,0,0.1,0.1,0.1c0.3,0.4,0.6,0.7,1,0.9c-0.4,0.9-0.3,1.9,0.2,2.7c0.6,1,1.7,1.6,2.8,1.5
			c0.4,0,0.7-0.1,1.1-0.1c0.2,0.2,0.4,0.4,0.6,0.5c0.4,0.2,0.9,0.3,1.3,0.3c0.9,0,1.7-0.3,2.4-0.8c2.3-1.4,5-4.5,7.5-7.4
			c2.7-3.1,5.5-6.3,7.4-7.1L58,41v-2.1l-1.2,0.3c0,0-0.1,0-0.1,0c-2.4,1-5.2,4.2-8.2,7.7c-2.5,2.8-5.1,5.8-7.2,7.1
			c-0.5,0.4-1.1,0.5-1.6,0.4c-0.1-0.1-0.1-0.3-0.1-0.4c0.6-0.8,1.2-1.6,1.9-2.3c3.2-3.7,8-9.2,5-12.3c0,0,0,0,0-0.1
			c0,0,0-0.1-0.1-0.1l0,0C46.2,39.1,46,39,45.8,39c-2.5-0.4-6.9-1.4-7.9-2.8c-0.1-0.1-0.1-0.2-0.1-0.4
			C37.8,34.7,38.5,34.5,38.9,34.5z M34.6,49.6C34.6,49.5,34.6,49.5,34.6,49.6c-0.4-0.6-0.3-1.3,0.2-1.7c0,0,0.1-0.1,0.1-0.1
			c0.7-0.5,2.6-1.6,4.5-2.6l1-0.6c-1.5,1.9-3.2,3.6-5.1,5.1C35.1,49.9,34.8,49.8,34.6,49.6z M39.9,50.4c-1.7,1.9-2.2,2.6-2.3,3.3
			c-0.8,0.2-1.4,0.1-1.7-0.4c-0.3-0.8,0-1.6,0.7-2c2.5-1.9,6.8-7,8.7-9.4C45.3,44.2,41.9,48.1,39.9,50.4z"/>
  </svg>
);
const IconSelection = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
  	<path d="M31.2,43.2c0.2,0,0.5,0,0.8,0c1.7,0,4.2-0.3,5.9-2c2.7-2.7,1.9-7.5,1.9-7.7c-0.1-0.4-0.4-0.8-0.8-0.8
			c-0.2,0-5-0.8-7.7,1.8V26c1,1.2,2.1,2,3.2,2c0.3,0,0.6-0.1,0.9-0.2c1.6-0.7,2-3.2,1-5.9c1.2,0.4,2.4,0.7,3.4,0.7
			c0.2,0,0.4,0,0.6,0c1.5-0.1,2.5-0.8,3-1.8c0.5-1.9-1.3-4.2-4.5-5.6c2.6-1.3,4-3.4,3.3-5c-0.7-1.6-3.1-2-5.8-1
			c0.5-1.4,0.8-2.8,0.7-4c-0.1-1.5-0.8-2.6-1.8-3c-1.9-0.7-4.3,1.1-5.6,4.3c-1.3-2.6-3.4-4-5-3.3c-0.9,0.4-1.5,1.4-1.6,2.8
			c-0.1,0.9,0.1,2,0.5,3.1c-3.2-1.2-6.1-0.7-6.9,1.1c-0.4,1-0.1,2.2,0.9,3.3c0.8,0.9,2,1.6,3.3,2.2c-2.5,1.3-3.9,3.4-3.2,5
			c0.4,0.9,1.4,1.5,2.8,1.6c0.1,0,0.2,0,0.3,0c0.8,0,1.8-0.2,2.7-0.5c-0.5,1.4-0.7,2.7-0.6,3.9c0.1,1.5,0.8,2.6,1.8,3
			c0.3,0.1,0.6,0.2,0.9,0.2c1.2,0,2.5-0.9,3.6-2.4v8c-2.8-2.6-7.6-1.9-7.8-1.8c-0.4,0.1-0.8,0.4-0.8,0.8c0,0.2-0.8,5.1,1.9,7.7
			c1.7,1.7,4.2,2,5.9,2c0.3,0,0.6,0,0.8,0V52H8v2h21.2v4h2v-4H35v-2h-3.8V43.2z M23.9,39.8c-1.4-1.4-1.5-4-1.4-5.2
			c1.5-0.1,3.9,0,5.2,1.4c1.4,1.4,1.4,3.9,1.4,5.2C27.7,41.3,25.2,41.2,23.9,39.8z M34.4,26c-0.4,0.2-1.7-0.7-2.7-2.8
			c-0.6-1.4-0.7-2.7-0.5-3.4c0.3-0.1,0.6-0.2,0.9-0.4c0.6,0.4,1.5,1.3,2.1,2.7C35.1,24.3,34.7,25.8,34.4,26z M41.3,20.1
			c-0.1,0.2-0.5,0.5-1.3,0.5c-0.9,0.1-2.1-0.1-3.3-0.6c-1.5-0.6-2.5-1.4-3-2.1c0.1-0.2,0.3-0.4,0.4-0.7c0.1-0.2,0.1-0.5,0.2-0.7
			c0.8-0.2,2.2-0.1,3.7,0.5C40.4,18,41.5,19.5,41.3,20.1z M36.4,11.4c2.2-1,3.8-0.7,3.9-0.3c0.1,0.3-0.7,1.7-2.9,2.6
			c-1.4,0.6-2.6,0.7-3.3,0.6c-0.1-0.3-0.2-0.6-0.4-0.9C34.1,12.8,35,12,36.4,11.4z M31.4,7.5c1-2.5,2.5-3.6,3.1-3.4
			C34.7,4.2,35,4.6,35,5.4c0.1,0.9-0.1,2.1-0.6,3.3c-0.6,1.5-1.5,2.6-2.2,3.1c-0.2-0.1-0.4-0.2-0.7-0.3c-0.2-0.1-0.4-0.1-0.7-0.2
			C30.7,10.4,30.8,9,31.4,7.5z M25.5,5.1L25.5,5.1c0.6,0,1.8,0.8,2.7,2.9c0.6,1.4,0.7,2.6,0.5,3.3c-0.1,0-0.3,0.1-0.4,0.1
			c-0.2,0.1-0.3,0.2-0.4,0.2c-0.6-0.4-1.5-1.3-2.1-2.7c-0.4-1-0.6-2-0.6-2.9C25.1,5.6,25.3,5.2,25.5,5.1z M19.2,12.2
			c-0.5-0.6-0.7-1-0.6-1.2c0.2-0.5,2.1-0.9,4.5,0.1c1.5,0.6,2.6,1.5,3.1,2.2c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1,0.2-0.1,0.4-0.2,0.6
			c-0.8,0.2-2.2,0.1-3.7-0.5C20.9,13.5,19.8,12.9,19.2,12.2z M23.4,19.6c-0.9,0.5-1.9,0.8-2.8,0.7c-0.6,0-1-0.2-1-0.4
			c-0.2-0.4,0.7-1.7,2.8-2.7c1-0.4,1.9-0.6,2.6-0.6c0.3,0,0.5,0,0.7,0.1c0.1,0.2,0.1,0.4,0.2,0.5c0.1,0.1,0.1,0.3,0.2,0.4
			C25.7,18.2,24.8,19,23.4,19.6z M28.5,23.5c-1,2.5-2.5,3.6-3.1,3.4c-0.2-0.1-0.5-0.5-0.5-1.3c-0.1-0.9,0.1-2.1,0.6-3.3
			c0.6-1.5,1.5-2.5,2.1-3c0.2,0.1,0.5,0.3,0.7,0.4c0.2,0.1,0.4,0.1,0.7,0.2C29.1,20.7,29.1,22,28.5,23.5z M29.1,17.6
			c-0.5-0.2-1-0.6-1.2-1.2c-0.2-0.5-0.2-1.1,0-1.7c0.2-0.5,0.6-1,1.2-1.2c0.3-0.1,0.6-0.2,0.9-0.2c0.3,0,0.6,0.1,0.8,0.2
			c1.1,0.5,1.7,1.7,1.2,2.9C31.6,17.5,30.3,18,29.1,17.6z M31.1,41.1c0-0.2,0-0.3,0-0.5v-0.2c0-1.4,0.3-3.3,1.4-4.5
			c1.4-1.4,4-1.5,5.2-1.4c0.1,1.3,0,3.8-1.4,5.2C34.9,41.1,32.3,41.2,31.1,41.1z M44.3,29.4l1.9-0.8l-1.7-4.2l-1.9,0.8L44.3,29.4z
			 M46.9,30.4l-1.8,0.8l0.8,1.8l1.8-0.8L46.9,30.4z M48,21.7l-2.1-0.9l-0.8,1.9l2.1,0.9L48,21.7z M55.2,26.8l0.8-1.9l-6.1-2.5
			l-0.8,1.9L55.2,26.8z M49.2,18l-0.8-1.8l-2.6,1.1l0.8,1.8L49.2,18z M52.9,16.5l-0.8-1.8l-1.8,0.8l0.8,1.8L52.9,16.5z M17.5,5.8
			L15.9,2l-1.9,0.8l1.5,3.8L17.5,5.8z M14.9,8.2L9.2,5.9L8.4,7.7l5.8,2.3L14.9,8.2z M7.3,5.1L5.5,4.4L4.7,6.2L6.6,7L7.3,5.1z
			 M10.9,12.9l0.8,1.8l2.6-1.1l-0.8-1.8L10.9,12.9z M8,16.3l1.8-0.8L9,13.7l-1.8,0.8L8,16.3z M15.5,48h-9v2h9V48z M2,54h3v-2H2V54z
			 M39,54v-2h-2v2H39z M41,54h4v-2h-4V54z M47,52v2h11v-2H47z M11,58h4v-2h-4V58z M17,58h11v-2H17V58z M54.6,48h-22v2h22V48z M22,48
			v2h6v-2H22z M36.6,58h4v-2h-4V58z M32.6,58h2v-2h-2V58z"/>
  </svg>
);
const IconAllYear = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
  	<path d="M53.8,13h2v-2h2V9h-2V7h-2v2h-2v2h2V13z M7.8,47h-2v2h-2v2h2v2h2v-2h2v-2h-2V47z M55.8,3h-2v2h2V3z M5.8,57h2
			v-2h-2V57z M29.6,11.9l-0.1,2.6c0,0.6,0.4,1,0.9,1.1c0.2,0,0.3,0,0.5-0.1l12-5.7c0.3-0.2,0.6-0.5,0.6-0.8c0-0.4-0.2-0.7-0.5-0.9
			L31.6,1.1c-0.5-0.3-1.1-0.1-1.4,0.3c-0.1,0.1-0.1,0.3-0.1,0.5L30,3.7C16.6,4.5,15.7,15.1,15.7,16.4v2.3
			C22.4,11.2,29.6,11.9,29.6,11.9z M31,5.7c0.5,0,0.9-0.4,1-0.9l0.1-1l8.3,5.1l-8.8,4.1l0.1-1.9c0-0.6-0.4-1-0.9-1.1
			c-4.5-0.2-9,1.1-12.5,3.9C19.1,10.7,22,5.9,31,5.7z M11.8,30.3l2.6,0.1c0.6,0,1-0.4,1.1-0.9c0-0.2,0-0.3-0.1-0.5L9.7,17
			c-0.2-0.3-0.5-0.6-0.8-0.6c-0.4,0-0.7,0.2-0.9,0.5L1,28.2c-0.3,0.5-0.1,1.1,0.3,1.4c0.1,0.1,0.3,0.1,0.5,0.1l1.8,0.1
			C4.4,43.2,15,44.1,16.2,44.1h2.3C11.1,37.4,11.8,30.3,11.8,30.3z M5.5,28.8c0-0.5-0.4-0.9-0.9-1l-1-0.1l5.1-8.3l4.1,8.8l-1.9-0.1
			c-0.6,0-1,0.4-1.1,0.9c-0.2,4.5,1.1,9,3.9,12.5C10.6,40.8,5.8,37.8,5.5,28.8z M30.1,48l0.1-2.6c0-0.6-0.4-1-0.9-1.1
			c-0.2,0-0.3,0-0.5,0.1l-12,5.7c-0.3,0.2-0.6,0.5-0.6,0.8c0,0.4,0.2,0.7,0.5,0.9l11.3,6.9c0.5,0.3,1.1,0.1,1.4-0.3
			c0.1-0.1,0.1-0.3,0.1-0.5l0.1-1.8C43.1,55.4,44,44.9,44,43.6v-2.3C37.3,48.8,30.1,48,30.1,48z M28.7,54.3c-0.5,0-0.9,0.4-1,0.9
			l-0.1,1l-8.3-5.1l8.8-4.1l-0.1,1.9c0,0.6,0.4,1,0.9,1.1c4.5,0.2,9-1.1,12.5-3.9C40.6,49.2,37.7,54.1,28.7,54.3z M58.4,30.4
			c-0.1-0.1-0.3-0.1-0.5-0.1l-1.8-0.1c-0.8-13.4-11.4-14.3-12.6-14.3h-2.3c7.5,6.8,6.7,13.9,6.7,13.9l-2.6-0.1c-0.6,0-1,0.4-1.1,0.9
			c0,0.2,0,0.3,0.1,0.5L50,43c0.2,0.3,0.5,0.6,0.8,0.6c0.4,0,0.7-0.2,0.9-0.5l6.9-11.3C59,31.3,58.8,30.7,58.4,30.4z M51,40.5
			l-4.1-8.8l1.9,0.1c0.6,0,1-0.4,1.1-0.9c0.2-4.5-1.1-9-3.9-12.5c3.1,0.9,7.9,3.9,8.2,12.9c0,0.5,0.4,0.9,0.9,1l1,0.1L51,40.5z
			 M42,24.7c-0.7-1.6-3.1-2-5.8-1c0.5-1.4,0.8-2.8,0.7-4c-0.1-1.5-0.8-2.6-1.8-3c-1.9-0.7-4.3,1.1-5.6,4.3c-1.3-2.6-3.4-4-5-3.3
			c-0.9,0.4-1.5,1.4-1.6,2.8c-0.1,0.9,0.1,2,0.5,3.1c-3.2-1.2-6.1-0.7-6.9,1.1c-0.4,1-0.1,2.2,0.9,3.3c0.8,0.9,2,1.6,3.3,2.2
			c-2.5,1.3-3.9,3.4-3.2,5c0.4,0.9,1.4,1.5,2.8,1.6c0.1,0,0.2,0,0.3,0c0.8,0,1.8-0.2,2.7-0.5c-0.5,1.4-0.7,2.7-0.6,3.9
			c0.1,1.5,0.8,2.6,1.8,3c0.3,0.1,0.6,0.2,0.9,0.2c0.8,0,1.7-0.5,2.6-1.2c1.8-1.9,1.9-3.7,1.9-3.7s0.5,1.3,1.4,2.4
			c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.1,0.1,0.2,0.2c0.8,0.8,1.7,1.4,2.6,1.4c0.3,0,0.6-0.1,0.9-0.2c1.6-0.7,2-3.2,1-5.9
			c1.2,0.4,2.4,0.7,3.4,0.7c0.2,0,0.4,0,0.6,0c1.5-0.1,2.5-0.8,3-1.8c0.5-1.9-1.3-4.2-4.5-5.6C41.3,28.4,42.7,26.3,42,24.7z
			 M31.2,22c1-2.5,2.5-3.6,3.1-3.4c0.2,0.1,0.5,0.5,0.5,1.3c0.1,0.9-0.1,2.1-0.6,3.3c-0.6,1.5-1.5,2.6-2.2,3.1
			c-0.2-0.1-0.4-0.2-0.7-0.3c-0.2-0.1-0.4-0.1-0.7-0.2C30.5,24.9,30.6,23.5,31.2,22z M25.3,19.6h0.1c0.5,0,1.7,0.8,2.6,2.9
			c0.6,1.4,0.7,2.6,0.5,3.3c-0.1,0-0.3,0.1-0.4,0.1c-0.2,0.1-0.3,0.2-0.4,0.2c-0.6-0.4-1.5-1.3-2.1-2.7c-0.4-1-0.6-2-0.6-2.9
			C24.9,20.1,25.1,19.7,25.3,19.6z M19,26.7c-0.5-0.6-0.7-1-0.6-1.2c0.2-0.5,2.1-0.9,4.5,0.1c1.5,0.6,2.6,1.5,3.1,2.2
			c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1,0.2-0.1,0.4-0.2,0.6c-0.8,0.2-2.2,0.1-3.7-0.5C20.7,28,19.6,27.4,19,26.7z M23.2,34.1
			c-0.9,0.5-1.9,0.8-2.8,0.7c-0.6,0-1-0.2-1-0.4c-0.2-0.4,0.7-1.7,2.8-2.7c1-0.4,1.9-0.6,2.6-0.6c0.3,0,0.5,0,0.7,0.1
			c0.1,0.2,0.1,0.4,0.2,0.5c0.1,0.1,0.1,0.3,0.2,0.4C25.5,32.7,24.6,33.5,23.2,34.1z M28.3,38c-1,2.5-2.5,3.6-3.1,3.4
			c-0.2-0.1-0.5-0.5-0.5-1.3c-0.1-0.9,0.1-2.1,0.6-3.3c0.6-1.5,1.5-2.5,2.1-3c0.2,0.1,0.5,0.3,0.7,0.4c0.2,0.1,0.4,0.1,0.7,0.2
			C28.9,35.2,28.9,36.5,28.3,38z M28.9,32.1c-0.5-0.2-1-0.6-1.2-1.2c-0.2-0.5-0.2-1.1,0-1.7c0.2-0.5,0.6-1,1.2-1.2
			c0.3-0.1,0.6-0.2,0.9-0.2s0.6,0.1,0.8,0.2c1.1,0.5,1.7,1.7,1.2,2.9C31.4,32,30.1,32.5,28.9,32.1z M34.2,40.5
			c-0.4,0.2-1.7-0.7-2.7-2.8c-0.6-1.4-0.7-2.7-0.5-3.4c0.3-0.1,0.6-0.2,0.9-0.4c0.6,0.4,1.5,1.3,2.1,2.7
			C34.9,38.8,34.5,40.3,34.2,40.5z M41.1,34.6c-0.1,0.2-0.5,0.5-1.3,0.5c-0.9,0.1-2.1-0.1-3.3-0.6c-1.5-0.6-2.5-1.4-3-2.1
			c0.1-0.2,0.3-0.4,0.4-0.7c0.1-0.2,0.1-0.5,0.2-0.7c0.8-0.2,2.2-0.1,3.7,0.5C40.2,32.5,41.3,34,41.1,34.6z M37.2,28.2
			c-1.4,0.6-2.6,0.7-3.3,0.6c-0.1-0.3-0.2-0.6-0.4-0.9c0.4-0.6,1.3-1.4,2.7-2c2.2-1,3.8-0.7,3.9-0.3C40.2,25.9,39.4,27.3,37.2,28.2z
			"/>
  </svg>
);

const Icon = ({ type }) => {
	switch(type) {
		case 'Care': return (<IconCare />)
		case 'Greenhouse': return (<IconGreenhouse />)
		case 'Renewable': return (<IconRenewable />)
		case 'Seedling': return (<IconSeedling />)
		case 'Selection': return (<IconSelection />)
		case 'AllYear': return (<IconAllYear />)
		default: return
	}
};

export { Logo, Embleme, Facebook, Instagram, Pattern, Icon }