import React, { useState } from "react"
import PropTypes from 'prop-types';
import { StaticQuery, graphql, Link } from 'gatsby';

import { Logo, Facebook, Instagram } from '../Subcomponent/SVG';

const Nav = ({ edges }) => {
  const menu = edges;
  const menuSorted = menu.sort(function(a, b){
    return a.node.data.sort - b.node.data.sort;
  });

  const [menuOpen, setMenuOpen] = useState(false);

  function setMenuState(state) {
		if (state) {
      setMenuOpen(false)
    } else {
      setMenuOpen(true)
    }
	}

  return (
    <nav className={menuOpen ? 'opened' : ''}>

      <div className="head">
        <div className="logo">
          <Link to="/">
            <Logo />
          </Link>
        </div>
        <div
          className={menuOpen ? 'hamburger opened' : 'hamburger'}
          onClick={event => setMenuState(menuOpen)}
        >
          <div className="bar"></div>
        </div>
      </div>

      <div className="content">
        <ul className="menu">
          {menuSorted.map((item, i) => (
            <li key={i}>
              <Link
                to={"/" + item.node.uid}
                activeClassName="active"
                partiallyActive={true}
              >
                {item.node.data.title.text}
              </Link>
            </li>
          ))}
        </ul>
      </div>

    </nav>
  )
}

Nav.propTypes = {
  edges: PropTypes.array,
  location: PropTypes.object,
};

const Navigation = props => (
  <StaticQuery
    query={graphql`
      query {
        allPrismicPage {
          edges {
            node {
              id
              data {
                title {
                  text
                }
                sort
              }
              uid
            }
          }
        }
      }
    `}
    render={data => (
      <Nav {...data.allPrismicPage} {...props} />
    )}
  />
);

export default Navigation;